<form [formGroup]="editPasswordForm" (ngSubmit)="savePassword()" class="user-form" novalidate>
  <div class="alert alert-success tt__m-t-10 tt__displa-flex" *ngIf="formSuccessText">
    {{ formSuccessText }}
  </div>

  <div class="form-group">
    <label class="control-label" i18n>New password</label>
    <input
      #newPasswordInput
      type="password"
      formControlName="newPassword"
      autocorrect="off"
      autocapitalize="off"
      class="form-control"
      appEye
    />
    <app-validated-input
      class="tooltip-style"
      [errors]="editPasswordForm.get('newPassword').errors"
      [hidden]="
        editPasswordForm.get('newPassword').valid || !editPasswordForm.get('newPassword').touched
      "
    >
      <app-validation-message i18n displayOn="required"
        >New password is required.</app-validation-message
      >
      <app-validation-message displayOn="minlength" i18n
        >Please enter at least 6 characters.</app-validation-message
      >
    </app-validated-input>
  </div>

  <div class="form-group">
    <label class="control-label" i18n>Re-enter new password</label>
    <input
      type="password"
      validateEqual="newPassword"
      autocorrect="off"
      autocapitalize="off"
      formControlName="newPasswordConfirm"
      class="form-control"
      appEye
    />
    <app-validated-input
      class="tooltip-style"
      [errors]="editPasswordForm.get('newPasswordConfirm').errors"
      [hidden]="
        editPasswordForm.get('newPasswordConfirm').valid ||
        !editPasswordForm.get('newPasswordConfirm').touched
      "
    >
      <app-validation-message i18n displayOn="validateEqual"
        >Passwords do not match.</app-validation-message
      >
      <app-validation-message displayOn="minlength" i18n
        >Please enter at least 6 characters.</app-validation-message
      >
    </app-validated-input>
  </div>

  <div>
    <div class="form-group">
      <label class="control-label">Current password</label>
      <input
        #currentPasswordInput
        type="password"
        autocorrect="off"
        autocapitalize="off"
        formControlName="currentPassword"
        class="form-control"
        appEye
      />
      <app-validated-input
        class="tooltip-style"
        [errors]="editPasswordForm.get('currentPassword').errors"
        [hidden]="
          editPasswordForm.get('currentPassword').valid ||
          !editPasswordForm.get('currentPassword').touched
        "
      >
        <app-validation-message i18n displayOn="required"
          >Current password is required.</app-validation-message
        >
      </app-validated-input>
    </div>
  </div>

  <div class="form-group">
    <button
      [disabled]="loading"
      class="tt--button button--blue large tt__m-0"
      appLoadingState
      [loadingState]="loading"
      i18n
    >
      Save new password
    </button>
  </div>

  <app-validated-input class="alert-style" [errors]="error">
    <app-validation-message displayOn="PASSWORD_WEAK"
      >Invalid new password. Please use 6 characters, an uppercase, a lowercase and a numeric
      character.</app-validation-message
    >
    <app-validation-message displayOn="PASSWORD_COMMON"
      >Common password, choose another</app-validation-message
    >
    <app-validation-message displayOn="*"
      >There was an error changing your password</app-validation-message
    >
  </app-validated-input>
</form>

<ng-template #confirmPasswordChangeModal>
  <section class="tt__modal">
    <p class="password-confirm-content">
      Resetting your password will log you out of your account.
    </p>
    <div class="modal-footer text-right">
      <button
        type="submit"
        [disabled]="loading"
        class="tt--button button--red large"
        (click)="confirmChangePassword()"
      >
        Confirm
      </button>
      <button (click)="passwordModalRef.hide()" type="button" class="tt--button button--blue large">
        Close
      </button>
    </div>
  </section>
</ng-template>
