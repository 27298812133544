import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new Subject<string>();
  toastState: Observable<string>;

  constructor() {
    this.toastState = this.toastSubject.pipe(shareReplay(1));
  }

  showToast(message: string) {
    this.toastSubject.next(message);
  }
}
