import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, RendererFactory2 } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvisibleCaptchaService {
  private currentCaptchaObs = new Subject<string>();
  private widgetId: any;
  private readyObs = new BehaviorSubject(false);
  private renderFactory: RendererFactory2;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) document: Document,
    renderFactory: RendererFactory2
  ) {
    this.renderFactory = renderFactory;
    if (isPlatformBrowser(platformId)) {
      const timer = setInterval(() => {
        if (window.grecaptcha?.enterprise) {
          this.captchaInit();
          clearInterval(timer);
        }
      }, 500);
    }
  }

  private captchaInit() {
    window.grecaptcha.enterprise.ready(() => {
      this.readyObs.next(true);
      const renderer = this.renderFactory.createRenderer(null, null);
      const captchaContainer = renderer.createElement('div');
      renderer.appendChild(document.body, captchaContainer);
      this.widgetId = window.grecaptcha.enterprise.render(captchaContainer, {
        'sitekey': environment.recaptchaInvisibleKey,
        'size': 'invisible',
        'callback': (response) => {
          this.currentCaptchaObs.next(response);
          //window.grecaptcha.enterprise.reset(this.widgetId);
        },
        'expired-callback': () => {
          console.warn('Invisible captcha expired');
          //window.grecaptcha.enterprise.reset(this.widgetId);
        },
        'error-callback': () => {
          this.currentCaptchaObs.next(null);
          //window.grecaptcha.enterprise.reset(this.widgetId);
        },
      });
    });
  }

  public tryExecute() {
    return this.readyObs
      .pipe(
        filter((ready) => ready),
        switchMap(() => {
          if (isPlatformBrowser(this.platformId) && window.grecaptcha?.enterprise) {
            grecaptcha.enterprise.execute(this.widgetId);
            return this.currentCaptchaObs;
          } else {
            console.log('grecaptcha not loadded');
            return Promise.reject();
          }
        })
      )
      .pipe(take(1))
      .toPromise();
  }
}
